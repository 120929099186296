.qualified {
  font-weight: bold;
}

img.medal {
  display: inline-block;
  height: 1.4em;
}

.results-table {
  th, td {
    white-space: nowrap;
  }

  margin-bottom: 10px;
}

a.entity-link {
  color: inherit;
}

.help-needed-callout {
  padding: 10px;
  border-radius: 15px;
  background-color: #fbf7d4;
}
