$wide-content-width: 1360px;

@import "minima";

html > body {
  font-family: 'Roboto', sans-serif;
}

p, ul {
  font-size: 16px;
}

.wide-wrapper {
  max-width: -webkit-calc(#{$wide-content-width} - (30px * 2));
  max-width: calc(#{$wide-content-width} - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

pre {
  border: 0px;
  padding: 6px 9px;
  margin-bottom: 2px;
}

blockquote {
  font-style: normal;
}

@media (hover: none) {
  .scrollable-x {
    overflow-x: auto;
  }
}

.footer-col-1 {
  width: -webkit-calc(40% - (#{$spacing-unit} / 2));
  width:         calc(40% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(60% - (#{$spacing-unit} / 2));
  width:         calc(60% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

// https://stackoverflow.com/questions/15844500/shrink-a-youtube-video-to-responsive-width
.video-wrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 10px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Strip some default table decorations from Minima.
table.rouge-table {
  margin-bottom: 0px;
  border: 0px;
  
  td {
    padding: 0px ($spacing-unit / 4);
    border: 0px;

  }

  td:last-child {
    width: 100%;
  }
}

// Better-looking MDL tabs.
.mdl-tabs__tab-bar {
  margin-bottom: 15px;
}

a.mdl-tabs__tab {
  font-weight: 700;
  font-size: 16px;
}

@media (hover: none) {
  .mdl-tabs__tab-bar.scrollable-x {
    justify-content: unset;
    padding-bottom: 20px;
  }

  a.mdl-tabs__tab {
    flex-shrink: 0;
  }
}

// TODO remove this, find a way to use a special class for images in topics.
.post-content img {
  display: block;
  margin: auto;
}

.post-content img.emoji {
  display: inline;
}

img.region-symbol {
  display: inline;
  height: 1.2em;
  margin-right: 5px;
};

h1.entity-name {
  font-size: 52px;
  letter-spacing: -1px;
}

h2.entity-subtitle {
  font-size: 26px;
  letter-spacing: -1px;
}

.section-break {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  color: lightgray;
  margin: 20px 0px;
}

@import "results_page";
@import "participant_profile";
@import "school_profile";
